import React from "react";
import { currentUserAtom, useAtom } from "~/atoms";
import type { CurrentUser } from "~/types";

export function useCurrentUser(user: CurrentUser | null = null) {
  const [currentUser, setCurrentUser] = useAtom(currentUserAtom);
  React.useEffect(() => {
    if (!user) return;
    setCurrentUser(user);
  }, [user, setCurrentUser]);
  return [currentUser, setCurrentUser] as const;
}
